import { Box, CircularProgress } from "@material-ui/core";
import { AuthContext } from "api/AuthContext";
import { CustomText } from "common/Reusables";
import React, { useContext } from "react";
import { Each } from "./Each";
import { AddChatIcon } from "./Icons";
import CtaDialog from "./components/CtaDialog";
import GroupCard from "./components/GroupCard";
import UserCard from "./components/UserCard";
import "./style.css";
import { conversationTimeFormat } from "./util";

const ChatList = ({
  setOpenSearch,
  openSearch,
  search,
  handleMessage,
  setActiveContent,
  selected,
  startChatMutation,
  onlineUsers,
  handleGroupMessage,
  selectedGroup,
  notification,
  groupNotification,
  isTyping,
  isTypingGroup,
  data,
  chatQuery,
}) => {
  const { user } = useContext(AuthContext);
  const [show, setShow] = React.useState(false);
  return (
    <Box
      sx={{
        width: "314px",
        bgcolor: "white",
        height: "100%",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "23px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "9px",
            alignItems: "center",
          }}
        >
          <Box>
            {user?.photo ? (
              <img
                src={user?.photo}
                alt="profile"
                style={{ width: "40px", height: "40px", borderRadius: "5px" }}
              />
            ) : (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "5px",
                  bgcolor: "#F1F5F9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "19.937px",
                  fontWeight: "600",
                  fontFamily: "Black Sans",
                }}
              >
                {user?.first_name ? user?.first_name[0] : "A"}
              </Box>
            )}
          </Box>
          {user?.first_name ? (
            <CustomText
              color="#171717"
              fontFamily="Black Sans"
              fontSize="16px"
              fontWeight="600"
            >
              {`${user?.first_name} ${user?.last_name}`}
            </CustomText>
          ) : (
            <CustomText
              color="#171717"
              fontFamily="Black Sans"
              fontSize="16px"
              fontWeight="600"
            >
              John Doe
            </CustomText>
          )}
        </Box>
        <Box
          onClick={() => {
            setShow(true);
          }}
          sx={{
            height: "40px",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <AddChatIcon />
          {show && (
            <CtaDialog
              top="2rem"
              {...{ setShow, openSearch, setOpenSearch, setActiveContent }}
            />
          )}
        </Box>
      </Box>

      <Box mb={"23px"} border={"1px solid #E1E1E1"}>
        {/* <TextField
          variant="outlined"
          size="small"
          value={search}
          placeholder="Search for employee"
          style={{
            backgroundColor: "#F3F3F3",
            width: "100%",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>

      {chatQuery?.isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Box
          className="scrollbar"
          sx={{
            height: "calc(100vh - 100px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {data && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Each
                of={data.sort((a, b) => (a.pin === b.pin ? 0 : a.pin ? -1 : 1))}
                render={(chat) =>
                  chat.employee_one_id ? (
                    <UserCard
                      isTyping={isTyping}
                      time={conversationTimeFormat(
                        chat?.last_message?.created_at
                      )}
                      user={user}
                      notification={notification}
                      employee={
                        chat?.employee_one_id === user?.id
                          ? {
                              ...chat?.employee_two,
                              room_id: chat?.id,
                              unread: chat?.unread,
                              sender: user.id,
                              last_message: chat?.last_message,
                              isOnline: onlineUsers?.includes(
                                chat?.employee_two?.id
                              ),
                              isPinned: chat?.pin,
                              blocked_one: chat?.blocked_one,
                              blocked_two: chat?.blocked_two,
                              employee_one_id: chat.employee_one_id,
                              employee_two_id: chat.employee_two_id,
                            }
                          : {
                              ...chat?.employee_one,
                              room_id: chat?.id,
                              unread: chat?.unread,
                              sender: user.id,
                              last_message: chat?.last_message,
                              isOnline: onlineUsers?.includes(
                                chat?.employee_one?.id
                              ),
                              isPinned: chat?.pin,
                              blocked_one: chat?.blocked_one,
                              blocked_two: chat?.blocked_two,
                              employee_one_id: chat.employee_one_id,
                              employee_two_id: chat.employee_two_id,
                            }
                      }
                      unread={chat?.unread}
                      isChat={true}
                      {...{
                        handleMessage,
                        selected,
                        startChatMutation,
                        onlineUsers,
                      }}
                    />
                  ) : (
                    <GroupCard
                      user={user}
                      isTypingGroup={isTypingGroup}
                      group={chat}
                      unread={chat?.unread}
                      groupNotification={groupNotification}
                      {...{
                        handleGroupMessage,
                        selectedGroup,
                        user,
                      }}
                    />
                  )
                }
              />
            </Box>
          )}{" "}
        </Box>
      )}
    </Box>
  );
};

export default ChatList;
