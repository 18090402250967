import { Box, Drawer } from "@material-ui/core";
import { AuthContext } from "api/AuthContext";
import axiosInstance, { getBaseDomainURL } from "api/axios.auth.config";
import SnackBar from "common/SnackBar";
import { apiErrorHandler } from "common/utils";
import { getMethod } from "hooks/getHook";
import { deleteReq, patchReq, postReq, sendFormData } from "hooks/postHook";
import moment from "moment";
import React, { useCallback, useContext, useEffect } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { socket } from "socket";
import ChatList from "./ChatList";
import MessagePanel from "./MessagePanel";
import AddGroupMember from "./components/AddGroupMember";
import CreateGroup from "./components/CreateGroup";
import DeleteDialog from "./components/DeleteDialog";
import ForwardMessageDialog from "./components/ForwardMessageDialog";
import GroupList from "./components/GroupList";
import GroupMessagePanel from "./components/GroupMessagePanel";
import SearchPanel from "./components/SearchPanel";
import { extractEmoji } from "./util";
const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
const ChatDrawer = ({ openChat, setOpenChat }) => {
  const [roomId, setRoomId] = React.useState(null);
  const [activeContent, setActiveContent] = React.useState("Message");
  const [activeUser, setActiveUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const {
    user,
    chats,
    chatQuery,
    onlineUsers,
    isTypingGroup,
    isTyping,
    employees,
    refetchEmployees,
    params,
    setParams,
    notification,
    setNotification,
    groupNotification,
    setGroupNotification,
    isLoadingEmployee,
    departments,
  } = useContext(AuthContext);
  const [openSearch, setOpenSearch] = React.useState(false);

  const [selected, setSelected] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("text");
  const [actionType, setActionType] = React.useState("Chat");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openForwardDialog, setOpenForwardDialog] = React.useState(false);
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [createGroupData, setCreateGroupData] = React.useState({});

  const [isPinned, setIsPinned] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [groupFiles, setGroupFiles] = React.useState([]);
  const [isGroupPinned, setIsGroupPinned] = React.useState(false);
  const [isGroupMute, setIsGroupMute] = React.useState(false);
  const [groupDetails, setGroupDetails] = React.useState({});
  const [groupId, setGroupId] = React.useState(null);
  const [groupConversation, setGroupConversation] = React.useState({});
  const [activeGroup, setActiveGroup] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [editorInstance, setEditorInstance] = React.useState(null);
  const [parentMessage, setParentMessage] = React.useState(null);
  const [messageAction, setMessageAction] = React.useState(null);
  const [openAddMember, setOpenAddMember] = React.useState(false);
  const [messageId, setMessageId] = React.useState(null);
  const [roomIds, setRoomIds] = React.useState([]);
  const [employeeIds, setEmployeeIds] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState([]);
  const [isBlocked, setIsBlocked] = React.useState({
    block_one: false,
    block_two: false,
  });
  const [clearConversation, setClearConversation] = React.useState(false);
  const messagesEndRef = React.useRef(null);
  const domain = localStorage.getItem("business_id");

  const handleSendMessage = (e) => {
    e.preventDefault();
    socket.emit("chat", {
      chat_room: roomId,
      message: message,
      sender: user.id,
      receiver: activeUser?.id,
      message_type: "text",
      parent: messageAction === "Quote reply" ? parentMessage?.id : null,
      business_id: domain,
    });
    chatQuery?.refetch();
    setMessage(""); // Reset the message state
    editorInstance?.commands?.clearContent();

    setParentMessage(null);
    setMessageAction(null);
    setOpenEmoji(false);
  };

  const handleSendMessageToGroup = (e) => {
    e.preventDefault();
    socket.emit("group-chat", {
      group: groupId,
      message: message,
      sender: user.id,
      message_type: "text",
      parent: messageAction === "Quote reply" ? parentMessage?.id : null,
      business_id: domain,
    });
    chatQuery?.refetch();
    setParentMessage(null);
    setMessageAction(null);
    setMessage(""); // Reset the message state
    editorInstance?.commands?.clearContent();

    setOpenEmoji(false);
  };
  const uploadFileMutation = useMutation(sendFormData, {
    onSuccess: (res) => {
      if (activeContent === "Group Chat") {
        socket.emit("group-chat", {
          group: groupId,
          message: fileName,
          sender: user.id,
          message_type: messageType,
          parent: null,
          business_id: domain,
          file: res.data.key,
        });
        setMessage("");
        setMessageType("text");
      } else {
        socket.emit("chat", {
          chat_room: activeUser?.room_id,
          message: fileName,
          sender: activeUser?.sender,
          receiver: activeUser?.id,
          message_type: messageType,
          file: res.data.key,
          parent: null,
          business_id: domain,
        });
        setMessage("");
        setMessageType("text");
      }
    },
  });

  const [isFileTooLarge, setIsFileTooLarge] = React.useState(false);
  const handleFileUpload = useCallback((file) => {
    // if (file.size > 10485760) {
    //   return setIsFileTooLarge(true);
    // }

    setFileName(file.name);
    if (file.type.includes("image")) {
      setMessageType("image");
    } else if (file.type.includes("pdf")) {
      setMessageType("pdf");
    } else if (file.type.includes("mp4")) {
      setMessageType("video");
    } else {
      setMessageType("doc");
    }

    const formData = new FormData();
    getBase64(file).then((result) => {
      formData.append("file", file);
      uploadFileMutation.mutate({
        route: "/chat_uploads/create_upload_form_data/",
        // route: "/chat_uploads/",
        payload: formData,
      });
    });
    // eslint-disable-next-line
  }, []);

  const addMessage = (message) => {
    // Get today's date in yyyy-mm-dd format
    const today = new Date().toISOString().slice(0, 10);

    // Create a copy of the messages object to avoid mutating state directly
    const updatedMessages = { ...messages };

    // Check if today's date already exists as a key in the messages object
    if (updatedMessages[today]) {
      // If it exists, push the new message to the array of messages for today's date
      updatedMessages[today].push(message);
    } else {
      // If it doesn't exist, create a new array with the new message and set it as the value for today's date
      updatedMessages[today] = [message];
    }

    // Update the state with the updated messages object
    setMessages(updatedMessages);
  };

  const addMessageToGroupConversation = (message) => {
    // Get today's date in yyyy-mm-dd format
    const today = new Date().toISOString().slice(0, 10);

    // Create a copy of the messages object to avoid mutating state directly
    const updatedMessages = { ...groupConversation };

    // Check if today's date already exists as a key in the messages object
    if (updatedMessages[today]) {
      // If it exists, push the new message to the array of messages for today's date
      updatedMessages[today].push(message);
    } else {
      // If it doesn't exist, create a new array with the new message and set it as the value for today's date
      updatedMessages[today] = [message];
    }

    // Update the state with the updated messages object
    setGroupConversation(updatedMessages);
  };
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  useEffect(() => {
    // const roomId = activeUser?.room_id;
    socket.once(`chat-${domain}-${roomId}`, (data) => {
      addMessage(data);
      setIsInitialLoad(true);
    });

    return () => {
      socket.removeListener(`chat-${domain}-${roomId}`);
    };
    // eslint-disable-next-line
  }, [messages, roomId, domain]);

  useEffect(() => {
    socket.once(`group-chat-${domain}-${groupId}`, (data) => {
      addMessageToGroupConversation(data);
      setIsInitialLoad(true);
    });

    return () => {
      socket.removeListener(`group-chat-${domain}-${groupId}`);
    };
    // eslint-disable-next-line
  }, [groupConversation, groupId, domain]);

  const [isFocused, setIsFocused] = React.useState(false);

  useEffect(() => {
    if (isFocused) {
      socket.emit("typing", {
        business_id: domain,
        room_id: roomId,
        receiver_id: activeUser?.id,
        message: "true",
      });
    } else {
      socket.emit("typing", {
        business_id: domain,
        room_id: roomId,
        receiver_id: activeUser?.id,
        message: "false",
      });
    }
  }, [isFocused, domain, roomId, activeUser]);

  // tying indictor for group chat

  React.useEffect(() => {
    if (isFocused) {
      socket.emit("typing", {
        business_id: domain,
        room_id: groupId,
        is_group: true,
        sender_id: user.id,
        message: `${user.first_name} is typing`,
      });
    } else {
      socket.emit("typing", {
        business_id: domain,
        room_id: groupId,
        is_group: true,
        sender_id: user.id,
        message: `false`,
      });
    }
  }, [isFocused, domain, groupId, user]);

  // typing indicator for group chat ends here

  const fetchMessages = async ({ pageParam }) => {
    const response = await axiosInstance.get(pageParam);
    return response.data;
  };

  // Individual conversations infinitQuery
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isSuccess,
  } = useInfiniteQuery(
    ["chatMessages", roomId],
    ({
      pageParam = `${getBaseDomainURL()}/chats/get_specific_room_messages/${roomId}/?page=1&page_size=50`,
    }) => fetchMessages({ pageParam }),
    {
      enabled: Boolean(roomId),
      getNextPageParam: (firstPage) => firstPage.next || undefined,
      onSuccess: (res) => {
        let data = res.pages.map((data) => data.results);
        const combineAndSortMessages = (data) => {
          // Combine messages by date
          const combinedData = data.reduce((acc, current) => {
            Object.entries(current).forEach(([date, messages]) => {
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date] = acc[date].concat(messages);
            });
            return acc;
          }, {});

          // Convert the combined data to an array and sort by date
          const sortedData = Object.entries(combinedData).sort(
            (a, b) => new Date(a[0]) - new Date(b[0])
          );

          // Convert back to an object
          return Object.fromEntries(sortedData);
        };

        const sortedCombinedData = combineAndSortMessages(data);
        setMessages(sortedCombinedData);
      },
    }
  );

  // Group conversations infinitQuery
  const {
    fetchNextPage: fetchGroupNextPage,
    hasNextPage: hasGroupNextPage,
    isFetchingNextPage: isGroupFetchingNextPage,
    isLoading: isLoadingGroupMessage,
  } = useInfiniteQuery(
    ["chatMessages", groupId],
    ({
      pageParam = `${getBaseDomainURL()}/group_chats/get_specific_group_messages/${groupId}/?page=1&page_size=50`,
    }) => fetchMessages({ pageParam }),
    {
      enabled: Boolean(groupId),
      getNextPageParam: (firstPage) => firstPage.next || undefined,
      onSuccess: (res) => {
        let data = res.pages.map((data) => data.results);
        const combineAndSortMessages = (data) => {
          // Combine messages by date
          const combinedData = data.reduce((acc, current) => {
            Object.entries(current).forEach(([date, messages]) => {
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date] = acc[date].concat(messages);
            });
            return acc;
          }, {});

          // Convert the combined data to an array and sort by date
          const sortedData = Object.entries(combinedData).sort(
            (a, b) => new Date(a[0]) - new Date(b[0])
          );

          // Convert back to an object
          return Object.fromEntries(sortedData);
        };

        const sortedCombinedData = combineAndSortMessages(data);
        setGroupConversation(sortedCombinedData);
        chatQuery?.refetch();
      },
    }
  );
  const observer = React.useRef();
  const firstElementRef = React.useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          if (roomId) {
            fetchNextPage();
          } else if (groupId) {
            fetchGroupNextPage();
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchGroupNextPage,
      fetchNextPage,
      groupId,
      roomId,
    ]
  );

  const groupDetailQuery = useQuery(
    [
      "get-group-details",
      {
        route: `/groups/get_members_of_a_groups/${groupId}/`,
        params: {},
      },
    ],
    getMethod,

    {
      onSuccess: (res) => {
        setGroupDetails(res);
        chatQuery?.refetch();
      },
      enabled: Boolean(groupId),
    }
  );
  const [groupInfo, setGroupInfo] = React.useState({});
  const groupInfoQuery = useQuery(
    [
      "get-group-info",
      {
        route: `/groups/${groupId}/`,
        params: {},
      },
    ],
    getMethod,

    {
      onSuccess: (res) => {
        setGroupInfo(res);
        chatQuery?.refetch();
      },
      enabled: Boolean(groupId),
    }
  );

  const startChatMutation = useMutation(postReq, {
    onError: (error) => {
      setSelected(null);
    },
    onSuccess: ({ data }) => {
      setActiveContent("Message");
      setIsBlocked({
        ...isBlocked,
        block_one: data?.blocked_one,
        block_two: data?.blocked_two,
      });
      setActiveUser(data.employee_two);
      setRoomId(data?.id);
      setOpenSearch(false);
      setSelected(null);
      chatQuery?.refetch();
    },
  });

  const chatRoomUpdateMutation = useMutation(patchReq, {
    onSuccess: (res) => {
      chatQuery?.refetch();
      setOpen(false);
      groupDetailQuery.refetch();
      setOpenAddMember(false);

      if (groupId) {
        groupInfoQuery.refetch();
      }
    },
  });
  const deleteChatMutation = useMutation(deleteReq, {
    onSuccess: (res) => {
      refetchEmployees();
      chatQuery?.refetch();
      setOpen(false);
      setOpenDeleteDialog(false);
      setActiveUser(null);
      setRoomId(null);
      setGroupId(null);
      setActiveGroup(null);
    },
  });
  const softDeleteMutation = useMutation(postReq, {
    onSuccess: (res) => {
      chatQuery?.refetch();
      setOpen(false);
      setOpenDeleteDialog(false);
      setClearConversation(false);
    },
  });

  const createGroupMutation = useMutation(postReq, {
    onSuccess: (res) => {
      setGroupId(res.data.id);
      setIsGroupPinned(res.data.pin);
      setActiveGroup(res.data);
      setActiveContent("Group Chat");
      setActionType("Create Group");
      setOpen(false);
      chatQuery?.refetch();
    },
  });

  const handleActions = (action) => {
    console.log(
      activeUser.employee_one_id,
      activeUser.employee_two_id,
      user?.id
    );

    setActionType(action);
    if (action === "Pin chat" || action === "Unpin chat") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/chat_rooms/${activeUser.room_id}/`,
          payload:
            activeUser.employee_one_id === user?.id
              ? {
                  pin: !isPinned,
                }
              : {
                  pin_two: !isPinned,
                },
        })
        .then((res) => {
          setIsPinned(res.data.pin);
        });
    } else if (action === "Mute conversation") {
      chatRoomUpdateMutation.mutate({
        route: `/chat_rooms/${activeUser.room_id}/`,
        payload: {
          mute_chat: true,
        },
      });
    } else if (action === "Block contact" || action === "Unblock contact") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/chat_rooms/${activeUser.room_id}/`,
          payload: {
            block_one: !isBlocked.block_one,
            block_two: false,
          },
        })
        .then((res) => {
          setIsBlocked({
            ...isBlocked,
            block_one: res.data.blocked_one,
            block_two: res.data.blocked_two,
          });
        });
    } else if (action === "Delete conversation") {
      setOpenDeleteDialog(true);
      setOpen(false);
    } else {
      setClearConversation(!clearConversation);
      setOpen(false);
    }
  };
  const [deleteType, setDeleteType] = React.useState("");
  const handleGroupChatActions = (action) => {
    setActionType(action);
    if (action === "Pin chat" || action === "Unpin chat") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/groups/${groupId}/`,
          payload: {
            pin:
              action === "Pin chat"
                ? [...groupInfo.pin, user.id]
                : groupInfo.pin.filter((item) => item !== user.id),
          },
        })
        .then((res) => {
          setIsGroupPinned(res.data.pin.includes(user.id));
        });
    } else if (action === "Mute Group chat" || action === "Unmute Group chat") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/groups/${groupId}/`,
          payload: {
            mute_chat:
              action === "Mute Group chat"
                ? [...groupInfo.mute_chat, user.id]
                : groupInfo.mute_chat.filter((item) => item !== user.id),
          },
        })
        .then((res) => {
          setIsGroupMute(res.data.mute_chat.includes(user.id));
        });
    } else if (action === "Leave group") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/groups/${groupId}/`,
          payload: {
            members: groupInfo.members.filter((item) => item !== user.id),
          },
        })
        .then((res) => {
          setNotification(null);
          setActiveGroup(null);
          setGroupId(null);
          setActiveContent(null);
        });
    } else if (action === "Delete conversation" || action === "Delete") {
      setOpenDeleteDialog(true);
      setDeleteType("deleteGroup");
    } else if (action === "Add member") {
      setOpenAddMember(true);
    }
  };

  const [editMessage, setEditMessage] = React.useState(false);

  const handleChatActions = useCallback((action, message) => {
    if (action === "Quote reply") {
      setMessageAction(action);
    } else if (action === "Edit message") {
      setEditMessage(!editMessage);
      setMessageId(message.id);
    } else if (action === "Forward message") {
      setOpenForwardDialog(!openForwardDialog);
    } else if (action === "Add reaction") {
      console.log("add reaction");
    } else if (action === "Pin chat") {
      console.log("pin chat");
    } else if (action === "Delete message") {
      setParentMessage(message);
      setDeleteType("deleteMessage");
      setOpenDeleteDialog(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (event) => {
    if (message === "") {
      return;
    } else {
      if (event.key === "Enter" || event.key === "Return") {
        if (!event.shiftKey) {
          handleSendMessage(event);
        }
      }
    }
  };

  const handleKeyDownforGroup = (event) => {
    if (message === "") {
      return;
    } else {
      if (event.key === "Enter" || event.key === "Return") {
        if (!event.shiftKey) {
          handleSendMessageToGroup(event);
        }
      }
    }
  };
  const handleCreateGroupChange = (e) => {
    setCreateGroupData({ ...createGroupData, [e.target.name]: e.target.value });
  };

  const markAsReadMutation = useMutation(postReq, {
    onError: (error) => {
      setSelected(null);
    },
    onSuccess: (res) => {
      setOpenSearch(false);
      setSelected(null);
      chatQuery?.refetch();
    },
  });

  const handleMessage = (chat) => {
    setNotification(null);
    setActiveGroup(null);
    setGroupId(null);
    editorInstance?.commands?.clearContent();
    setMessage("");
    setIsPinned(chat?.isPinned);
    setIsBlocked({
      ...isBlocked,
      block_one: chat?.blocked_one,
      block_two: chat?.blocked_two,
    });
    setActiveContent("Message");
    markAsReadMutation.mutate({
      route: `/chats/post_mark_as_read/${chat.room_id}/`,
      payload: {
        chat_room: chat?.room_id,
      },
    });
    setOpenSearch(false);
    setActiveUser(chat);
    if (chat?.room_id) {
      setRoomId(chat?.room_id);
    } else {
      setRoomId(chat.chat_room);
    }
  };

  const handleGroupMessage = (group) => {
    setRoomId(null);
    setActiveUser(null);
    setGroupNotification(null);
    editorInstance?.commands?.clearContent();
    setMessage("");
    setActiveContent("Group Chat");
    setGroupId(group?.id);
    setIsGroupPinned(group?.pin);
    setIsGroupMute(group?.mute_chat);
    setActiveGroup(group);
    markAsReadMutation.mutate({
      route: `/group_chats/post_mark_as_read/${group?.id}/`,
      payload: {
        group: group?.id,
      },
    });
  };

  const getContentToShow = (actionType) => {
    switch (actionType) {
      case "Message":
        return (
          <MessagePanel
            isLoading={isLoading}
            {...{
              hasNextPage,
              openSearch,
              setOpenSearch,
              messages,
              roomId,
              activeUser,
              message,
              setMessage,
              handleSendMessage,
              chatRoomUpdateMutation,
              deleteChatMutation,
              handleActions,
              open,
              setOpen,
              handleChatActions,
              setEditorInstance,
              openEmoji,
              setOpenEmoji,
              handleFileUpload,
              handleKeyDown,
              isPinned,
              files,
              sharedFiles,
              setActiveContent,
              isTyping,
              parentMessage,
              setParentMessage,
              messageAction,
              setMessageAction,
              editMessage,
              messageId,
              setMessageId,
              isBlocked,
              isFetchingNextPage,
              firstElementRef,
              isSuccess,
              messagesEndRef,
              isInitialLoad,
              setIsInitialLoad,
              uploadFileMutation,
              setIsFocused,
            }}
          />
        );
      case "Create Group":
        return (
          <CreateGroup
            data={employees}
            {...{
              handleCreateGroupChange,
              setCreateGroupData,
              createGroupData,
              createGroupMutation,
              setActiveContent,
            }}
          />
        );
      case "Browse Groups":
        return <GroupList />;
      case "Group Chat":
        return (
          <GroupMessagePanel
            handleActions={handleGroupChatActions}
            handleKeyDown={handleKeyDownforGroup}
            isLoading={isLoadingGroupMessage}
            {...{
              hasGroupNextPage,
              employees,
              openSearch,
              setOpenSearch,
              messages,
              roomId,
              activeGroup,
              handleSendMessageToGroup,
              chatRoomUpdateMutation,
              deleteChatMutation,
              open,
              setOpen,
              handleChatActions,
              setEditorInstance,
              openEmoji,
              setOpenEmoji,
              handleFileUpload,
              handleKeyDownforGroup,
              isPinned,
              groupFiles,
              setGroupConversation,
              groupConversation,
              message,
              groupSharedFiles,
              groupId,
              groupDetails,
              isTypingGroup,
              user,
              parentMessage,
              setParentMessage,
              messageAction,
              setMessageAction,
              isGroupPinned,
              isGroupMute,
              groupInfo,
              handleMessage,
              startChatMutation,
              setActionType,
              editMessage,
              messageId,
              setMessageId,
              setActiveContent,
              setGroupId,
              setActiveGroup,
              setNotification,
              firstElementRef,
              isGroupFetchingNextPage,
              messagesEndRef,
              uploadFileMutation,
              setMessage,
              setIsFocused,
            }}
          />
        );
      default:
        return (
          <MessagePanel
            isLoading={isLoading}
            {...{
              hasNextPage,
              openSearch,
              setOpenSearch,
              messages,
              roomId,
              activeUser,
              message,
              setMessage,
              handleSendMessage,
              chatRoomUpdateMutation,
              deleteChatMutation,
              handleActions,
              open,
              setOpen,
              handleChatActions,
              setEditorInstance,
              openEmoji,
              setOpenEmoji,
              handleFileUpload,
              handleKeyDown,
              isPinned,
              files,
              setActiveContent,
              sharedFiles,
              isTyping,
              parentMessage,
              setParentMessage,
              messageAction,
              setMessageAction,
              editMessage,
              messageId,
              setMessageId,
              isBlocked,
              isFetchingNextPage,
              firstElementRef,
              isSuccess,
              messagesEndRef,
              isInitialLoad,
              setIsInitialLoad,
              uploadFileMutation,
              setIsFocused,
            }}
          />
        );
    }
  };

  const sharedFiles = useQuery(
    [
      "get-uploads",
      {
        route: `/chat_uploads/get_specific_room_uploads/${roomId}/`,
      },
    ],
    getMethod,
    {
      onSuccess: (res) => {
        const combinedArray = [].concat(...Object.values(res.results));
        const filteredArray = combinedArray.filter(
          (item) => item.message_type !== "shared_task"
        );
        setFiles(filteredArray);
      },
      enabled: Boolean(roomId),
    }
  );
  const groupSharedFiles = useQuery(
    [
      "get-group-uploads",
      {
        route: `/chat_uploads/get_specific_group_uploads/${groupId}/`,
      },
    ],
    getMethod,
    {
      onSuccess: (res) => {
        const combinedArray = [].concat(...Object.values(res.results));
        const filteredArray = combinedArray.filter(
          (item) => item.message_type !== "shared_task"
        );
        setGroupFiles(filteredArray);
      },
      enabled: Boolean(groupId),
    }
  );

  const handleEmployeeForwardMessage = () => {
    if (roomIds.length > 0 || employeeIds.length > 0) {
      for (const room of roomIds) {
        socket.emit("chat", {
          chat_room: room.chat_room,
          message: extractEmoji(parentMessage?.message),
          sender: user?.id,
          receiver: room?.id,
          forwarded: true,
          message_type: parentMessage?.message_type,
          parent: null,
          business_id: domain,
        });
      }

      for (const employee of employeeIds) {
        startChatMutation
          .mutateAsync({
            route: "/chat_rooms/",
            payload: {
              employee_one: user.id,
              employee_two: employee.id,
              pin: false,
              mute_chat: false,
            },
          })
          .then((res) => {
            socket.emit("chat", {
              chat_room: res.data.id,
              message: extractEmoji(parentMessage?.message),
              sender: user?.id,
              receiver: employee?.id,
              forwarded: true,
              message_type: parentMessage?.message_type,
              parent: null,
              business_id: domain,
            });
          });
      }

      setSelected([]);
      setEmployeeIds([]);
      setRoomIds([]);
      setOpenForwardDialog(false);
    }
  };

  const handleForwardMessageToGroup = (group) => {
    for (const id of selectedGroup) {
      socket.emit("group-chat", {
        group: id,
        message: extractEmoji(parentMessage?.message),
        sender: user.id,
        message_type: parentMessage?.message_type,
        forwarded: true,
        parent: null,
        business_id: domain,
      });
    } // Reset the message state
    setSelected([]);
    setEmployeeIds([]);
    setRoomIds([]);
    setOpenForwardDialog(false);
  };

  const removeDeletedMessage = (message) => {
    const date = moment(message.created_at).format("YYYY-MM-DD");
    if (message.group) {
      const updatedData = {
        ...groupConversation,
        [date]: groupConversation[date].filter(
          (item) => item.id !== message.id
        ),
      };
      setGroupConversation(updatedData);
    } else {
      const updatedData = {
        ...messages,
        [date]: messages[date].filter((item) => item.id !== message.id),
      };
      setMessages(updatedData);
    }
  };

  return (
    <Drawer
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableRestoreFocus={true}
      style={{
        overflow: "hidden",
      }}
      anchor={"right"}
      open={openChat}
      onClose={() => {
        setOpenChat(false);
        uploadFileMutation.reset();
        setMessage(""); // Reset the message state
        editorInstance?.commands?.clearContent();
        setActiveUser(null);
        setRoomId(null);
        setGroupId(null);
        setActiveGroup(null);
        setParentMessage(null);
        setMessageAction(null);
        setOpenEmoji(false);
      }}
    >
      {chatRoomUpdateMutation.isSuccess && (
        <SnackBar>{`${actionType} successfully`}</SnackBar>
      )}

      {softDeleteMutation.isSuccess && (
        <SnackBar>{`${actionType} successfully`}</SnackBar>
      )}
      {softDeleteMutation.isError && (
        <SnackBar severity="error">
          {apiErrorHandler(softDeleteMutation.error)}
        </SnackBar>
      )}
      {isFileTooLarge && (
        <SnackBar severity="error">File size should not exceed 10MB</SnackBar>
      )}
      {deleteChatMutation.isSuccess && (
        <SnackBar>{`${actionType} successfully`}</SnackBar>
      )}
      {deleteChatMutation.isError && (
        <SnackBar severity="error">
          {apiErrorHandler(deleteChatMutation.error)}
        </SnackBar>
      )}

      {chatRoomUpdateMutation.isError && (
        <SnackBar severity="error">
          {apiErrorHandler(chatRoomUpdateMutation.error)}
        </SnackBar>
      )}
      {createGroupMutation.isSuccess && (
        <SnackBar>{`${actionType} successfully`}</SnackBar>
      )}

      {createGroupMutation.isError && (
        <SnackBar severity="error">
          {apiErrorHandler(createGroupMutation.error)}
        </SnackBar>
      )}
      {uploadFileMutation.isSuccess && (
        <SnackBar>{`File sent successfully`}</SnackBar>
      )}

      {uploadFileMutation.isError && (
        <SnackBar severity={"error"}>
          {apiErrorHandler(uploadFileMutation.error)}
        </SnackBar>
      )}

      <Box
        sx={{
          height: "100%",
          bgcolor: "#FAFAFA",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          position: "relative",
        }}
      >
        <Box width={"314px"}>
          <ChatList
            selectedGroup={groupId}
            selected={roomId}
            data={chats}
            {...{
              openSearch,
              setOpenSearch,
              handleMessage,
              setActiveContent,
              startChatMutation,
              onlineUsers,
              handleGroupMessage,
              notification,
              groupNotification,
              isTyping,
              isTypingGroup,

              chatQuery,
            }}
          />
        </Box>
        {openSearch && (
          <SearchPanel
            data={employees}
            {...{
              selected,
              setSelected,
              setParams,
              params,
              openSearch,
              setOpenSearch,
              startChatMutation,
              handleMessage,
              isBlocked,
              setIsBlocked,
              setActiveUser,
              setRoomId,
            }}
          />
        )}

        {getContentToShow(activeContent)}
      </Box>

      {(openDeleteDialog || clearConversation) && (
        <DeleteDialog
          message={parentMessage}
          actionType={deleteType}
          {...{
            setOpenDeleteDialog,
            deleteChatMutation,
            softDeleteMutation,
            clearConversation,
            setClearConversation,
            groupId,
            roomId,
            user,
            removeDeletedMessage,
          }}
        />
      )}

      {openForwardDialog && (
        <ForwardMessageDialog
          data={employees}
          groups={chats.filter((item) => !item.employee_one_id)}
          {...{
            params,
            setParams,
            setOpenForwardDialog,
            parentMessage,
            startChatMutation,
            handleEmployeeForwardMessage,
            handleForwardMessageToGroup,
            roomIds,
            setRoomIds,
            selectedGroup,
            setSelectedGroup,
            setEmployeeIds,
            employeeIds,
          }}
        />
      )}

      {openAddMember && (
        <AddGroupMember
          data={employees?.filter(
            (item) => !groupInfo?.members.includes(item.id)
          )}
          {...{
            setOpenAddMember,
            groupInfo,
            chatRoomUpdateMutation,
            setParams,
            params,
            isLoadingEmployee,
            departments,
          }}
        />
      )}
    </Drawer>
  );
};

export default ChatDrawer;
